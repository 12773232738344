// @ts-check
import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"

import Card from "../card"
import colors, { hoverColors } from "../../styles/colors"
import { typographyComponents } from "../typography"
import { BigPillButton } from "../button"
import { trackEvent } from "../../utils/trackEvent"
import { MobileMediaQuery } from "../../styles/constants"

const Columns = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
  }
`
const IllustrationWrapper = styled.div`
  position: relative;
  padding-right: 70px;
  cursor: pointer;
`
const RoundImage = styled(Img)`
  max-width: 100%;
  width: 450px;
  border-radius: 450px;
  /* Safari fix */
  img {
    border-radius: 450px;
  }
`
const PlaySvg = styled.svg`
  position: absolute;
  bottom: -5px;
  left: 30px;
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 20vw;
    height: 20vw;
  }
`
const ArrowSvg = styled.svg`
  position: absolute;
  top: 5px;
  right: -5px;
  width: 210px;
  height: 210px;

  @media (max-width: 768px) {
    width: 30vw;
    height: 30vw;
  }
`

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 769px) {
    padding-left: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`
const Suptitle = styled(typographyComponents.overline)`
  color: ${colors.primary};
`.withComponent("p")
const Title = styled(typographyComponents.h3)`
  margin-bottom: 20px;
`.withComponent("h3")
const Content = styled(typographyComponents.bodyLarge)``.withComponent("p")

const ButtonLink = styled(BigPillButton)`
  height: 90px;
  background-color: ${p =>
    p.variant ? colors[p.variant || "primary"] : colors.primary};
  color: ${colors.white};
  max-width: 330px;
  width: 100%;
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }

  &:hover {
    background-color: ${p => hoverColors[p.variant || "primary"]};
    border-color: ${p => hoverColors[p.variant || "primary"]};
  }
`.withComponent("a")

/**
 * @param {import("../card").CardProps & {href: string, childImageSharp: any, title: string, content: string, suptitle?: string}} props
 */
const CardWebinar = ({
  title,
  suptitle = "On-demand Webinar",
  content,
  href,
  childImageSharp,
  children,
  ...props
}) => {
  return (
    <Card variant="white" {...props}>
      <Columns>
        <IllustrationWrapper
          onClick={() => {
            window.open(href, "_blank")
            trackEvent({
              category: "view_item",
              label: "webinar picture link opened",
              siteSection: "webinar_hero",
            })
          }}
        >
          <RoundImage
            alt="Photo of the host of the webinars"
            fluid={childImageSharp.fluid}
          />

          {/* Play button */}
          <PlaySvg viewBox="0 0 102 102">
            <circle cx="51" cy="51" r="51" fill={colors.primary} />
            <path fill={colors.light} d="M73 51L40 70V32l33 19z" />
          </PlaySvg>
          {/* Arrow */}
          <ArrowSvg viewBox="0 0 210 210">
            <path
              fill={colors.primary}
              d="M5.3 204.7c3.6 3.6 8.3 5.3 13 5.3h173.4a18.3 18.3 0 000-36.6H62.4L202 33.8A18.3 18.3 0 00202 8a18.3 18.3 0 00-25.8 0L36.6 147.6V18.3C36.6 8.3 28.3 0 18.3 0S0 8.2 0 18.3v173.4c0 4.7 1.8 9.4 5.3 13z"
            />
          </ArrowSvg>
        </IllustrationWrapper>
        <CopyWrapper>
          <Suptitle>{suptitle}</Suptitle>
          <Title>{title}</Title>
          <Content>{content}</Content>

          <ButtonLink
            href={href}
            target="_blank"
            rel="noopener noreferer"
            onClick={() =>
              trackEvent({
                category: "view_item",
                label: "webinar watch now link opened",
                siteSection: "webinar_hero",
              })
            }
          >
            Watch now
          </ButtonLink>
        </CopyWrapper>
      </Columns>
    </Card>
  )
}

export default CardWebinar
