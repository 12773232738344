// @ts-check
import styled from "@emotion/styled"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card, { CardTitle } from "../components/card"
import colors from "../styles/colors"
import { useStaticQuery, graphql } from "gatsby"
import CardWebinar from "../components/cards/cardWebinar"
import CardSeparator from "../components/cardSeparator"

const CardWithTitleOnly = styled(Card)`
  padding-bottom: 0;
`

const MediaPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativePath: {
            in: [
              "webinars/tilda.png"
              "webinars/lucas.png"
              "webinars/jared.png"
            ]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(
                maxWidth: 450
                quality: 80
                traceSVG: {
                  color: "#333"
                  background: "#ddd"
                  blackOnWhite: true
                }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const nodes = data.allFile.edges.map(n => n.node)
  const tilda = nodes.find(n => n.relativePath === "webinars/tilda.png")
  const lucas = nodes.find(n => n.relativePath === "webinars/lucas.png")
  const jared = nodes.find(n => n.relativePath === "webinars/jared.png")
  return (
    <Layout bg="white" activeTab="learn">
      <SEO title="Webinars" />

      <CardWithTitleOnly noMinHeight variant="white">
        <CardTitle variant="h1" as="h1" style={{ color: colors.primary }}>
          Webinars
        </CardTitle>
      </CardWithTitleOnly>

      <CardWebinar
        title="Why chatbots suck at support"
        content="In this 30-minute webinar, you’ll learn why chatbots aren’t made for support, and how to make up for it. This webinar is hosted by Lucas Otterling, Head of Startups at Mavenoid."
        href="https://resources.mavenoid.com/why-chatbots-suck-on-demand-webinar/"
        childImageSharp={lucas.childImageSharp}
      />
      <CardSeparator variant="dimgrey" />
      <CardWebinar
        title="AI+People: A hybrid approach to CX"
        content="Learn how to use the hybrid support model to improve customer satisfaction and support agent efficiency. This webinar is hosted by Jared Accettura, Mavenoid’s Head of Sales Development."
        href="https://resources.mavenoid.com/mavenoid-hybrid-support-on-demand-webinar/"
        childImageSharp={jared.childImageSharp}
      />
      <CardSeparator variant="dimgrey" />
      <CardWebinar
        title="Improve your customer support during lockdowns"
        content="Learn how to use automation to tackle the most acute challenges for support teams during the covid-19 pandemic. The webinar is held by Tilda Sander, Head of Growth at Mavenoid."
        href="https://resources.mavenoid.com/improve-cx-in-lockdowns-webinar/"
        childImageSharp={tilda.childImageSharp}
      />
    </Layout>
  )
}

export default MediaPage
